@use '@angular/material' as mat;

@mixin devfu-styles-reset-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    a {
        color: mat.m2-get-color-from-palette($foreground, text);

        &:hover {
            color: mat.m2-get-color-from-palette($accent);
        }
    }

    code {
        color: mat.m2-get-color-from-palette($accent, lighter-contrast);
        background-color: mat.m2-get-color-from-palette($accent, lighter);
    }
}
