@use '@angular/material' as mat;

$format_colors: #6eae63, #bf7877, #647db9,
#a673bf, #bc8635,#8ba7b3 ,#80594d,
#74b38d,#b7a95e, #bf73ac;

@mixin devfu-documents-format-listing-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    devfu-format-listing {

        button {
            border-color: mat.m2-get-color-from-palette($primary, lighter) !important;

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
                color: mat.m2-get-color-from-palette($accent, darker) !important;
            }
        }

        .file-listing {
            @for $i from 1 to length($format_colors) + 1 {

                .file-chip-#{$i} {
                    background-color: nth($format_colors, $i) !important;

                    a, span {
                        color: white !important;
                    }
                }
            }
        }


        .dropzone {
            border-color: mat.m2-get-color-from-palette($primary, lighter) !important;
        }

        .alert.alert-warning {
            background-color: mat.m2-get-color-from-palette($warning, lighter, 0.8);
            color: mat.m2-get-color-from-palette($warning, darker, 0.8);
        }

        .alert.alert-info {
            background-color: mat.m2-get-color-from-palette($primary, lighter, 0.3);
            color: mat.m2-get-color-from-palette($primary, darker, 0.8);
        }

        .file-preview {

            .format-ordinal, .file-ordinal {
                background: mat.m2-get-color-from-palette($primary, A700, 0.8) !important;
                color: mat.m2-get-color-from-palette($primary, 50) !important;
            }

            .fa-spinner {
                color: mat.m2-get-color-from-palette($primary, 100, 0.7);
            }

            @for $i from 1 to length($format_colors) + 1 {

                &.file-#{$i} {

                    img {
                        border-color: nth($format_colors, $i) !important;
                    }

                    .file-ordinal {
                        background-color: nth($format_colors, $i) !important;
                    }
                }

            }

        }
    }
}
