@use '@angular/material' as mat;
@import 'bootstrap/scss/variables';

@mixin devfu-search-results-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    .search-results-overlay-container {
        input {
            background-color: $white;
            color: $black;
        }

        .mat-divider {
            border-top-color: mat.m2-get-color-from-palette($primary, lighter) !important;
        }

        .scroll-container {
            background-color: $white;
            .mat-mdc-list-item {
                border-bottom-color: mat.m2-get-color-from-palette($primary, A700, 0.25) !important;

                &.summary {
                    background-color: mat.m2-get-color-from-palette($primary, 50) !important;
                    color: mat.m2-get-color-from-palette($primary, 700) !important;
                }

                &.header {
                    background-color: mat.m2-get-color-from-palette($primary, 100) !important;
                    color: mat.m2-get-color-from-palette($primary, 700) !important;
                    border-top-color: mat.m2-get-color-from-palette($primary, A700, 0.25) !important;
                    border-bottom-color: mat.m2-get-color-from-palette($primary, A700, 0.25) !important;
                }

                &:not(.summary):not(.header) {

                    &:hover {
                        background-color: mat.m2-get-color-from-palette($accent, 50);
                    }
                }
                .mat-mdc-chip-list {
                    .mat-mdc-chip {
                        background-color: mat.m2-get-color-from-palette($primary, 50) !important;
                        color: mat.m2-get-color-from-palette($primary, 700) !important;

                        &:hover {
                            background-color: mat.m2-get-color-from-palette($primary, 50) !important;
                            color: mat.m2-get-color-from-palette($primary, 700) !important;
                        }
                    }

                }
            }
        }
    }

}
