@use '@angular/material' as mat;

@mixin devfu-anthem-mat-dialog-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);
    .mat-mdc-dialog-actions {
        .mat-mdc-button {
            border-color: mat.m2-get-color-from-palette($primary, lighter) !important;
            background: white;

            &[color=warn] {
                background: white;
                border-color: mat.m2-get-color-from-palette($warning) !important;
            }

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
                color: mat.m2-get-color-from-palette($accent, darker) !important;

                &[color=warn] {
                    background-color: mat.m2-get-color-from-palette($warning, lighter) !important;
                    color: mat.m2-get-color-from-palette($warning, darker) !important;
                }
            }
        }
    }

}

.mat-mdc-dialog-container {
    //padding: 0 !important;

    h2.mat-mdc-dialog-title {
        padding: 0 24px;
    }

    .mat-mdc-dialog-title {
        font-size: 16px !important;
        padding: 2px 8px;
        display: none;

        img {
            max-height: 24px;
            max-width: 24px;
            margin-right: 8px;
        }

    }

    .mat-mdc-dialog-content {
        padding: 15px 24px 20px 24px !important;
    }

    .mat-mdc-dialog-actions {
        margin: 12px;
        padding: 8px;
        flex-wrap: nowrap;
        justify-content: center;

        button {
            transition: background-color 100ms ease-in, color 100ms ease-in;
            border: 0.5px solid !important;
            line-height: 24px !important;
            font-weight: 300 !important;

            &:hover {
                transition: background-color 300ms ease-out, color 300ms ease-out;
            }
        }


    }

}
