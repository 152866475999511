@use '@angular/material' as mat;

@mixin devfu-messages-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $warn: map-get($theme, warn);

    .icon {
        color: mat.m2-get-color-from-palette($accent);
    }

    .message {
        color: mat.m2-get-color-from-palette($primary, lighter);
        font-size: 0.8rem;
    }

}
