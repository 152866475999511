* {
    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }

    &:active,
    :focus {
        outline: none !important;
    }

}

label {
    margin: 0;
}

html,
body {
    height: 100%;
    font-feature-settings: "kern", "liga";
    -webkit-font-smoothing: antialiased;
}

code {
    white-space: nowrap;
    border-radius: 10px;
    padding: 0 8px 1px 8px;
}
