@use '@angular/material' as mat;

@mixin devfu-tree-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    .node-icon {
        color: mat.m2-get-color-from-palette($accent, darker);
    }

}
