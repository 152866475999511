@use '@angular/material' as mat;

@mixin devfu-anthem-mat-chips-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);
    .mat-mdc-chip {
        background-color: mat.m2-get-color-from-palette($primary, default) !important;
        .mat-mdc-chip-remove {
            color: mat.m2-get-color-from-palette($primary, lighter) !important;
        }
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
        color: white !important;
    }
}


.mat-mdc-chip {
    border-radius: 6px !important;
    padding: 0 !important;
    font-size: 11px !important;
    font-weight: 400 !important;
}
