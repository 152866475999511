@use '@angular/material' as mat;

@mixin devfu-anthem-links-theme($theme) {
    $primary: map-get($theme, primary);

    a {
        font-weight: 400;
        color: mat.m2-get-color-from-palette($primary, darker);
    }

}
