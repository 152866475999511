@use '@angular/material' as mat;

@mixin devfu-anthem-mat-cards-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    .mat-mdc-card {
        .mat-mdc-card-title {
            background-color: mat.m2-get-color-from-palette($primary, default) !important;
            color: mat.m2-get-color-from-palette($primary, default-contrast) !important;
        }
        .mat-mdc-card-actions {

            button {
                border-color: mat.m2-get-color-from-palette($primary, lighter) !important;

                &:hover {
                    background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
                    color: mat.m2-get-color-from-palette($accent, darker) !important;
                }
            }
        }

    }

}

.mat-mdc-card {
    overflow: hidden;
    padding: 0 !important;
    margin-bottom: 30px;
    .mat-mdc-card-title {
        font-size: 18px !important;
        font-weight: 400 !important;
        padding: 8px !important;
        margin: 0 !important;

        img {
            max-height: 32px;
            max-width: 32px;
            margin-right: 8px;
        }

        span, button {
            vertical-align: middle;
        }

        .collapse-toggle {

            display: inline-block;
            float: right;
            padding-top: 6px;

            img {
                width: 24px;
                height: 24px;
            }
        }

    }
    .mat-mdc-card-subtitle {
        padding: 24px 24px 8px 24px !important;

        strong {
            font-weight: 300 !important;
        }
    }
    .mat-mdc-card-content {

    }
    .mat-mdc-card-actions {
        text-align: center;
        justify-content: center;
        gap: 5px;

        button {
            transition: background-color 100ms ease-in, color 100ms ease-in;
            border: 1px solid !important;
            line-height: 24px !important;
            font-weight: 300 !important;

            &:hover {
                transition: background-color 300ms ease-out, color 300ms ease-out;
            }
        }

        &:last-child {
            padding-bottom: 24px !important;
        }
    }


}

