@use '@angular/material' as mat;
@import 'app/components/pages/root/login/login.component.scss-theme';
@import 'app/components/pages/root/navbar/search-input/search-input.component.scss-theme';

@mixin devfu-navbar-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    @include devfu-login-component-theme($theme);
    @include devfu-navbar-search-input-component-theme($theme);

    mat-toolbar {

        background-color: mat.m2-get-color-from-palette($primary, darker) !important;

        .branding {
            color: #fff;
        }

        button {
            color: #fff;
            background-color: #ffffff00;
            &.active {
                color: mat.m2-get-color-from-palette($accent, default-contrast);
                background-color: mat.m2-get-color-from-palette($accent);
            }

            &:hover {
                color: mat.m2-get-color-from-palette($primary, default-contrast);
                background-color: mat.m2-get-color-from-palette($accent);
            }

            &.user-button {

                border-color: mat.m2-get-color-from-palette($primary, darker-contrast, 0.7) !important;

                &:hover {
                    background: mat.m2-get-color-from-palette($accent, lighter, 0.5) !important;
                }

            }
        }

        .sign-in-button {
            color: mat.m2-get-color-from-palette($accent);
            border: 1px solid mat.m2-get-color-from-palette($accent);
        }

        .link {
            color: mat.m2-get-color-from-palette($primary, default-contrast);
        }

        .username-display {
            color: mat.m2-get-color-from-palette($accent);
            font-variant: titling-caps;
        }

    }

}

