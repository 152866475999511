@use '@angular/material' as mat;

@mixin devfu-file-organizer-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    devfu-file-organizer {
        .file {

            .right {
                border-color: mat.m2-get-color-from-palette($primary, lighter) !important;

                .top-bar {
                    input {
                        background-color: mat.m2-get-color-from-palette($primary, darker, 0.4) !important;
                        color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;
                        border-bottom-color: mat.m2-get-color-from-palette($primary, lighter, 0.2) !important;
                    }

                    button {
                        &.movers {
                            background-color: mat.m2-get-color-from-palette($primary, darker, 0.2) !important;

                            img {
                                background-color: mat.m2-get-color-from-palette($primary, default, 0.65) !important;

                                &:hover {
                                    background-color: mat.m2-get-color-from-palette($primary, lighter, 0.8) !important;
                                }
                            }

                        }

                        &.actors {
                            background-color: mat.m2-get-color-from-palette($primary, darker, 0.4) !important;

                            img {
                                background-color: mat.m2-get-color-from-palette($primary, default, 0.8) !important;

                                &:hover {
                                    background-color: mat.m2-get-color-from-palette($primary, lighter, 0.8) !important;
                                }
                            }

                        }

                    }
                }

                .content {
                    .page-number {
                        background: mat.m2-get-color-from-palette($primary, A700, 0.8) !important;
                        color: mat.m2-get-color-from-palette($primary, 50) !important;
                    }
                }
            }

        }
    }
}
