@use '@angular/material' as mat;
@import 'app/components/pages/documents/details/file-organizer/file-organizer.component.scss-theme';
@import 'app/components/pages/documents/details/format-listing/format-listing.component.scss-theme';
@import 'app/components/pages/documents/details/review-listing/review-listing.component.scss-theme';
@import 'app/components/pages/documents/details/version-selector/version-selector.component.scss-theme';

@mixin devfu-documents-details-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    @include devfu-file-organizer-component-theme($theme);
    @include devfu-documents-format-listing-component-theme($theme);
    @include devfu-review-listing-component-theme($theme);
    @include devfu-version-selector-component-theme($theme);

    .change-card {
        .mat-mdc-card-title {
            background-color: #dadcbe !important; // mat-color($primary, lighter) !important;
            color: mat.m2-get-color-from-palette($primary, lighter-contrast) !important;
        }
    }

    .format-listing {
        div {
            border-color: mat.m2-get-color-from-palette($primary, lighter) !important;

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
            }
        }
    }

    .card-formats {
        .mat-mdc-tab-labels {
            background-color: mat.m2-get-color-from-palette($primary, default) !important;
        }
        .mat-mdc-tab-label-content {
            color: mat.m2-get-color-from-palette($primary, default-contrast) !important;
            font-weight: 300 !important;
            font-size: 18px !important;
        }

        .mat-ink-bar {
            background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
        }
    }

    .toolbar {
        background-color: mat.m2-get-color-from-palette($primary, darker) !important;

        button {
            color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;
        }

        .spacer {
            color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;
            opacity: 0.8;
        }

        // We need to move the toolbar above the footer for LG+ devices
        @media (min-width: 992px) {
            background-color: mat.m2-get-color-from-palette($primary, 700) !important;
        }
    }
}
