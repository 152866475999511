@use '@angular/material' as mat;

@mixin devfu-shared-atlas-menu-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    devfu-atlas-menu {
        .item-cancel {
            color: mat.m2-get-color-from-palette($warning, darker) !important;
        }
    }

}
