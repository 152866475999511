@use '@angular/material' as mat;

@mixin devfu-system-tags-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    .mat-mdc-card-subtitle {
        background-color: mat.m2-get-color-from-palette($primary, lighter) !important;
    }

    .tag-name-placeholder {
        opacity: 0.6;
    }

}
