// Add .vdivide to the row and it will add horizontal separators in between all columns
.row.vdivide > [class*='col-']:not(:last-child):after {
    background: #e0e0e0;
    width: 1px;
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    bottom: 0;
    right: 0;
    min-height: 50px;
}
