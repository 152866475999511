@use '@angular/material' as mat;

@mixin devfu-review-listing-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    .revision-header-row {

        background-color: mat.m2-get-color-from-palette($primary, 300) !important;
        color: white !important;

    }

}
