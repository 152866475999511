@use '@angular/material' as mat;

@mixin devfu-dashboard-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    devfu-dashboard {

        .title {
            color: mat.m2-get-color-from-palette($primary, darker);
        }

        .version {
            color: mat.m2-get-color-from-palette($primary, default, 0.8);
        }

    }

}
