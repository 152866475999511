@use '@angular/material' as mat;
@import 'app/components/pages/documents/view/version-select/version-select.component.scss-theme';

@mixin devfu-documents-view-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    @include devfu-documents-view-version-select-component-theme($theme);

    devfu-document-view {

        background: rgba(0, 0, 0, 0.8);

        color: #303030;

        mat-divider {
            border-top-color: mat.m2-get-color-from-palette($accent, lighter, 0.7) !important;
        }
        .mdc-list {
            h3 {
                color: white !important;
            }
            .mat-mdc-list-item {
                color: white !important;

                &:hover {
                    background-color: mat.m2-get-color-from-palette($accent, lighter, 0.3) !important;
                }
            }
        }

        .page-text {
            color: white;
        }

        .document-title {
            color: rgba(0, 0, 0, 0.8);
        }

        .view-phone-container {

            .no-versions-message {
                color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;
            }

            .toolbar {
                background-color: mat.m2-get-color-from-palette($primary, darker) !important;

                button {
                    color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;

                    &.version, &.view-style {
                        border-color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;

                        &[disabled] {
                            border-color: mat.m2-get-color-from-palette($primary, darker) !important;
                            color: mat.m2-get-color-from-palette($primary, lighter) !important;

                            &:hover {
                                background: mat.m2-get-color-from-palette($accent, lighter, 0.2) !important;
                            }
                        }

                        &:hover {
                            background: mat.m2-get-color-from-palette($accent, lighter, 0.5) !important;
                        }
                    }

                    &.open-document {
                        border-color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;

                        &:hover {
                            background: mat.m2-get-color-from-palette($accent, lighter, 0.5) !important;
                        }
                    }
                }

                .spacer {
                    color: mat.m2-get-color-from-palette($primary, darker-contrast) !important;
                    opacity: 0.8;
                }

                // We need to move the toolbar above the footer for LG+ devices
                @media (min-width: 992px) {
                    background-color: mat.m2-get-color-from-palette($primary, 700) !important;
                }
            }
        }
    }

    .page-number {
        background: rgba(0, 0, 0, 0.57);
        color: white;
    }
}
