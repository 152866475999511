@use '@angular/material' as mat;

@mixin devfu-app-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    mat-sidenav-container {

        mat-sidenav {
            a {
                &.active {
                    color: mat.m2-get-color-from-palette($accent, default-contrast);
                    background-color: mat.m2-get-color-from-palette($accent);
                }
            }

            .signature {
                .version {
                    color: mat.m2-get-color-from-palette($accent, default-contrast);
                }
            }
        }

        .footer {
            color: mat.m2-get-color-from-palette($primary, lighter);
            background-color: mat.m2-get-color-from-palette($primary, darker);

            .links,
            .signature {
                a {
                    color: mat.m2-get-color-from-palette($primary, lighter);

                    &:hover {
                        color: mat.m2-get-color-from-palette($accent);
                    }
                }
            }
        }
    }

    mat-sidenav-content {
        overflow: hidden;

        .bg-badge {
            z-index: -3;
            position: absolute;
            top: -20px;
            left: -20px;
            opacity: 0.1;
            transform: scale(2);
        }

    }

}
