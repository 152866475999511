@use '@angular/material' as mat;
@import 'styles-variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/utilities';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import 'styles-reset';


@include mat.core();

@import 'themes/default-theme';
@import 'themes/light-theme';
@import 'themes/black-theme';
@import 'themes/blue-theme';

@import 'styles-reset.scss-theme';
@import 'themes/anthem-base-theme';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'app/app.component.scss-theme';
@import 'app/shared/shared.scss-theme';
@import 'app/components/pages/atlas/atlas.scss-theme';
@import 'app/components/pages/documents/documents.scss-theme';
@import 'app/components/pages/root/root.scss-theme';
@import 'app/components/pages/settings/settings.scss-theme';
@import 'app/components/pages/tree/tree.scss-theme';
@import 'app/components/dialogs/dialogs.scss-theme';
@import 'app/components/pages/security/security.scss-theme';

@mixin custom-components-theme($theme) {
    @include devfu-styles-reset-theme($theme);
    @include devfu-anthem-base-theme($theme);
    @include devfu-app-component-theme($theme);
    @include devfu-root-theme($theme);
    @include devfu-shared-theme($theme);
    @include devfu-system-theme($theme);
    @include devfu-atlas-theme($theme);
    @include devfu-documents-theme($theme);
    @include devfu-security-theme($theme);
    @include devfu-dialogs-theme($theme);
    @include devfu-tree-theme($theme);
}

.default-theme {
    @include mat.all-component-colors($devfu-theme);
    @include custom-components-theme($devfu-theme);
}

.light-theme {
    @include mat.all-component-colors($devfu-light-theme);
    @include custom-components-theme($devfu-light-theme);
}

.black-theme {
    @include mat.all-component-colors($devfu-black-theme);
    @include custom-components-theme($devfu-black-theme);
}

.blue-theme {
    @include mat.all-component-colors($devfu-blue-theme);
    @include custom-components-theme($devfu-blue-theme);
}

h3 {
    font-size: 16px !important;
}

h4 {
    font-size: 14px !important;
}

.mat-mdc-card-content {
    padding: 0 !important;
}

.mdc-icon-button {
    font-size: unset !important;
}

.mdc-data-table__header-row {
    min-height: 32px !important;
}

.mat-sort-header-container, .mat-mdc-header-cell {
    font-weight: 400;
}

.search-form-field {
    background-color: #546e7a;
    border-radius: 6px;
    width: 600px;

    mat-icon {
        vertical-align: middle;
    }

    .search-icon {
        padding-bottom: 18px;
        font-size: 24px;
    }

    .cancel-button-wrapper {
        width: 24px;
        display: inline-block;
    }

    .cancel-button {
        font-size: 20px;
        padding-top: 1px;
        padding-bottom: 0;
        cursor: pointer;
    }
}

.search-field {
    background-color: #546e7a;
    border-radius: 6px;

    .mat-mdc-input-element {
        background-color: transparent;
        border: none;
        width: 550px;
        color: white
    }
}

.mat-mdc-card .mat-mdc-card-actions button, button.outline {
    font-weight: unset !important;
}

.mdc-button, .mat-mdc-tab .mdc-tab__text-label {
    letter-spacing: normal !important;
}

.mat-drawer-inner-container {
    overflow-x: hidden !important;
}

.expired {
    color: red;
}

span.vl {
    border: 1px solid white;
    border-radius: 1px;
    margin: 0 5px;
    height: 32px;
    display: inline-block;
}

.version-card-title-bar {
    min-height: 56px;
    display: flex !important;
    align-items: center;
}

//no data table rows

.no-data-row {
    text-align: center;
    padding: 20px 0;
    font-size: 1.2em;
    color: darkgrey;
    border: 1px solid #efefef;

    mat-icon {
        transform: scale(1.2);
    }
}

.large-table-content {
    padding: 20px 0;
    color: #cccccc;
    text-align: center;
    font-size: 30px;
}
