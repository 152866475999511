@use '@angular/material' as mat;

@mixin devfu-login-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $warn: map-get($theme, warn);

    .login-button {

        color: mat.m2-get-color-from-palette($accent) !important;
        border: 1px solid mat.m2-get-color-from-palette($accent);

        &[disabled] {
            color: rgba(0, 0, 0, 0.26) !important;
            border: none;
        }

        &:hover {
            color: mat.m2-get-color-from-palette($primary, default-contrast);
            background-color: mat.m2-get-color-from-palette($accent);
        }

    }

    .error-message {
        color: mat.m2-get-color-from-palette($warn);
        font-size: 0.7rem;
    }

}
