@use '@angular/material' as mat;

@mixin devfu-atlas-listing-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .onhover {
        &:hover {
            .material-icons {
                color: mat.m2-get-color-from-palette($accent, lighter-contrast) !important;

                &:hover {
                    color: mat.m2-get-color-from-palette($primary, default) !important;
                }
            }

            a {
                color: mat.m2-get-color-from-palette($accent, lighter-contrast) !important;

                &:hover {
                    color: mat.m2-get-color-from-palette($primary, default) !important;
                }
            }
        }
    }

}
