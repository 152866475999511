@use '@angular/material' as mat;
//@import 'category-listing/category-listing.component.scss-theme';

@mixin devfu-atlas-view-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    //@include devfu-atlas-category-listing-component-theme($theme);

    devfu-atlas-view {

        .top-category {
            background-color: mat.m2-get-color-from-palette($primary, default, 0.7);
            color: mat.m2-get-color-from-palette($primary, default-contrast);
            border-color: mat.m2-get-color-from-palette($primary, default, 0.8) !important;

            button {
                border-color: mat.m2-get-color-from-palette($primary, default, 0.6) !important;
                background-color: mat.m2-get-color-from-palette($primary, lighter);

                &:hover {
                    background-color: mat.m2-get-color-from-palette($accent, lighter, 0.7);
                    color: mat.m2-get-color-from-palette($primary, default-contrast);
                }

                &.remove {
                    mat-icon {
                        color: mat.m2-get-color-from-palette($warn, darker, 1) !important;
                    }
                }
            }
        }

        .sub-category {
            background-color: mat.m2-get-color-from-palette($primary, lighter);
            color: mat.m2-get-color-from-palette($primary, lighter-contrast);
            border-color: mat.m2-get-color-from-palette($primary, default, 0.8) !important;

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter, 0.7);
                color: mat.m2-get-color-from-palette($accent, lighter-contrast);
            }

            &.has-categories {
                background-color: mat.m2-get-color-from-palette($primary, default, 0.6);
                font-weight: 600;

                &:hover {
                    background-color: mat.m2-get-color-from-palette($accent, default, 0.8);
                    color: mat.m2-get-color-from-palette($accent, lighter-contrast);
                }

            }

            &.has-no-categories {
                background-color: mat.m2-get-color-from-palette($primary, default, 0.5);
                font-weight: 600;

                &:hover {
                    background-color: mat.m2-get-color-from-palette($accent, default, 0.8);
                    color: mat.m2-get-color-from-palette($accent, lighter-contrast);
                }
            }

        }

        .sub-document {
            border-color: mat.m2-get-color-from-palette($primary, default, 0.8) !important;

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter, 0.7);
                color: mat.m2-get-color-from-palette($accent, lighter-contrast);
            }
        }

        .add-entry {
            border:none !important;

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter, 0.7);
                color: mat.m2-get-color-from-palette($accent, lighter-contrast);
            }
        }

        .in-edit-mode {

            border-color: mat.m2-get-color-from-palette($primary, darker, 0.7) !important;

            .entry-container {
                .button-container {
                    button {
                        border-color: mat.m2-get-color-from-palette($primary, default, 0.8) !important;
                        background-color: mat.m2-get-color-from-palette($primary, lighter);
                        color: mat.m2-get-color-from-palette($primary, darker);

                        &:not([disabled]):hover {
                            background-color: mat.m2-get-color-from-palette($accent, lighter, 1);
                            color: mat.m2-get-color-from-palette($accent, lighter-contrast);
                        }

                        &[disabled] {
                            color: mat.m2-get-color-from-palette($primary, darker, 0.15);
                        }

                        &.add-child {
                            mat-icon {
                                color: forestgreen !important;
                            }

                            &[disabled] {
                                mat-icon {
                                    color: rgba(34, 139, 34, 0.3) !important;
                                }
                            }
                        }

                        &.remove {
                            mat-icon {
                                color: mat.m2-get-color-from-palette($warn, darker, 1) !important;
                            }
                        }
                    }
                }
            }

        }

    }

}
