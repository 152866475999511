@use '@angular/material' as mat;

@mixin devfu-anthem-mat-lists-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);
    .mat-mdc-list-item {

        &:hover {
            background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
        }

        &.selected {
            background-color: mat.m2-get-color-from-palette($accent, default) !important;
            color: mat.m2-get-color-from-palette($accent, default-contrast) !important;
        }

    }

}

.mdc-list {
    padding: 0 !important;
}

.mdc-list-item__content {
    padding: 5px 0;
}
