@use '@angular/material' as mat;

@mixin devfu-anthem-buttons-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    button.outline {
        border-color: mat.m2-get-color-from-palette($primary, lighter) !important;
        background: white;

        &[color=warn] {
            background: white;
            border-color: mat.m2-get-color-from-palette($warning) !important;
        }

        &:hover {
            background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
            color: mat.m2-get-color-from-palette($accent, darker) !important;
        }
    }

}

button.outline {
    transition: background-color 100ms ease-in, color 100ms ease-in;
    border: 1px solid !important;
    line-height: 24px !important;
    font-weight: 300 !important;

    &:hover {
        transition: background-color 300ms ease-out, color 300ms ease-out;
    }
}
