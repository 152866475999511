@use '@angular/material' as mat;

@mixin devfu-breadcrumbs-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    devfu-breadcrumbs {
        background-color: mat.m2-get-color-from-palette($primary, 100) !important;
        color: mat.m2-get-color-from-palette($primary, darker) !important;
        border-color: mat.m2-get-color-from-palette($primary, lighter) !important;

        .error {
            color: mat.m2-get-color-from-palette($accent, darker);
        }

        a:hover {
            color: mat.m2-get-color-from-palette($accent, darker);
        }

    }

}
