@use '@angular/material' as mat;

@mixin devfu-dialog-add-format-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .drop-zone {
        border-color: mat.m2-get-color-from-palette($primary, lighter) !important;
    }

    .file-entry, .invalid-file-entry {
        background: mat.m2-get-color-from-palette($primary, lighter) !important;
        color: mat.m2-get-color-from-palette($primary, darker) !important;
    }

    .invalid-file-entry {
        .mat-badge-content {
            color: white !important;
        }
    }

    .invalid-files-message {
        color: mat.m2-get-color-from-palette($warn) !important;
    }

}
