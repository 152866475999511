@use '@angular/material' as mat;

@mixin devfu-version-selector-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    devfu-version-selector {

        .version-entry {
            border-color: mat.m2-get-color-from-palette($primary, lighter) !important;

            button {
                border-color: mat.m2-get-color-from-palette($primary, lighter) !important;
                background-color: mat.m2-get-color-from-palette($accent, 100) !important;

                &:hover {
                    background-color: white !important;
                    color: mat.m2-get-color-from-palette($accent, darker) !important;
                }
            }

        }

        mat-icon {
            &.pointer {
                border-color: mat.m2-get-color-from-palette($primary, lighter) !important;
                background-color: mat.m2-get-color-from-palette($accent, 100) !important;

                &:hover {
                    background-color: white !important;
                    color: mat.m2-get-color-from-palette($accent, darker) !important;
                }

                &.disabled {
                    color: #aaaaaa;
                }
            }
        }
    }

}
