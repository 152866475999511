@use '@angular/material' as mat;

@mixin devfu-anthem-mat-tables-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warning: map-get($theme, warn);

    .mat-mdc-table {

        .mat-mdc-header-row {
            background: mat.m2-get-color-from-palette($primary, lighter) !important;

            button {
                color: mat.m2-get-color-from-palette($primary, darker) !important;
            }
        }

        .mat-mdc-row {

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent, lighter) !important;
            }

            &.selected-row {
                background-color: mat.m2-get-color-from-palette($accent, default) !important;
                color: mat.m2-get-color-from-palette($accent, default-contrast) !important;
            }

        }


    }

}


.mat-mdc-table {

    .mat-mdc-header-row {
        min-height: 32px !important;
    }

    .mat-mdc-row {
        min-height: 36px !important;

        .mat-mdc-cell {
            img {
                height: 24px !important;
            }

            strong {
                font-weight: 300 !important;
                font-size: 14px !important;
            }

            span {
                display: flex !important;
                align-items: flex-end !important;

                &.date-day {
                    width: 40px !important;
                }

                &.date-rest {
                    width: 150px !important;
                }
            }


        }

    }

}
