@mixin devfu-atlas-view-mobile-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    devfu-atlas-view-mobile {
        .folder-icon {
            color: rgb(168, 179, 185) !important;
        }
    }

}
