@use '@angular/material' as mat;

@mixin devfu-navbar-search-input-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    devfu-search-input {
        input {
            background-color: mat.m2-get-color-from-palette($primary, 600) !important;
            color: mat.m2-get-color-from-palette($primary, 50) !important;
        }
    }
}
